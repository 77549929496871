import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const thanks = () => {
    return (
        <div className='thanks'>
            <a href='https://www.travelcebu.ph'>
                <StaticImage
                    src='../images/tc-logo.png'
                    alt='tc-logo'
                    width={250}
                    className='logo'
                />
            </a>
            
            <h1 className='thanks-title'>Thanks For Reaching Us Out.</h1>
            <h3 className='thanks-subtitle'>We Will Get Back To You As Quickly As Possible.</h3>
            <a href='https://www.travelcebu.ph'>Visit Our Website</a>
        </div>
    )
}

export default thanks
